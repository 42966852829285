<!--试块检测-->
<template>
    <div class="test-Block tableList">
        <table-list
            ref="tableList"
            :extr="params"
            :code="params.code"
        ></table-list>
    </div>
</template>

<script>
import tableList from '@/views/tableList/index';
export default {
    // 运输调价列表
    name: 'test-block',
    components: { tableList },
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            params: {
                code: {
                    TableCode: 'test_block',
                    QueryString: '',
                },
            },
        };
    },
    computed: {},
    watch: {},
    created() {
        this.params.code.QueryString = '?task_number=' + this.extr.taskNumber;
    },
    mounted() { },
    destroyed() {},
    methods: {

    },
};
</script>
<style lang="stylus" scoped>
    .test-Block
        height 100%
        .currencyTable
            height 91%
            border 0;
        .queryCriteria .queryList
            display flex
            align-items center
        .time-range-inp
            width 3.6rem!important;
        >>> .el-link
            color: blue;
            text-decoration: underline;
            cursor: pointer;
            &:after
                display none!important;
        .el-input-group
            overflow hidden

    .main_theme1
        .el-button
            background #022782!important;
</style>
